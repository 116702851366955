import React from 'react';
import { initializeApp } from '@firebase/app';
import { getFirestore, doc, getDoc, setDoc } from '@firebase/firestore';
import { toastPromise } from '../Helpers/ToastMessage';
import 'react-image-upload/dist/index.css';
import Bubble from '../Helpers/Bubble.js';
import { getStorage, ref, uploadBytes } from "firebase/storage";
import { useCompany } from '../Store/storeFunctions';
import { BeatLoader } from 'react-spinners';

import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import {
    FormikCheckBoxSingle,
    FormikColorSelector,
    FormikEmailInput,
    FormikImageUpload,
    FormikPasswordInput,
    FormikSelect,
    FormikTextInput
} from '../FormikFields';


const app = initializeApp({
    projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
    authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
});

const db = getFirestore(app);
const storage = getStorage(app, process.env.REACT_APP_FIREBASE_STORAGE_BUCKET);

function CompanyOptions(props) {
    const [editColor, setEditColor] = React.useState('Primary');
    const [companyOptions, setCompanyOptions] = React.useState();
    const [logo, setLogo] = React.useState();
    const company = useCompany();

    const OptionsSchema = Yup.object().shape({
        name: Yup.string().required('Required'),
        email: Yup.string().email('Invalid email'),
        emailKey: Yup.string(),
        emailProvider: Yup.string(),
        emailReply: Yup.string(),
        emailChecklistStyle: Yup.string().required('Required'),
        logoPath: Yup.string(),
        primaryColor: Yup.string(),
        secondaryColor: Yup.string(),
        tertiaryColor: Yup.string(),
        interpolationData: Yup.object().shape({
            CompanyName: Yup.string(),
            CompanyEmail: Yup.string().email('Invalid email'),
            CompanyWebsite: Yup.string(),
            CompanyPortal: Yup.string(),
            CompanyPhone: Yup.string(),
            CompanyAddress: Yup.string()
        }),
        extraFeatures: Yup.object().shape({
          sharableStatus: Yup.boolean()
        })
    });

    React.useEffect(() => {
        if (company.companyData?.code && !companyOptions) {
            getCompanyOptions();
        }
    }, []);

    function getCompanyOptions() {
        toastPromise(async () => {
            var c = await getDoc(doc(db, `/BusinessRules/${company.companyData?.code}`));

            if (c && c.exists()) {
                var data = c.data();

                const co = {
                    email: data.email ?? '',
                    emailKey: data.emailKey ?? '',
                    emailProvider: data.emailProvider ?? 'gmail',
                    emailReply: data.emailReply ?? '',
                    name: data.name ?? '',
                    emailChecklistStyle: data.emailChecklistStyle ?? '',
                    logoPath: data.logoPath ?? '',
                    primaryColor: data.primaryColor ?? '',
                    secondaryColor: data.secondaryColor ?? '',
                    tertiaryColor: data.tertiaryColor ?? '',
                    stripeCustomerId: data.stripeCustomerId ?? '',
                    stripeSubscription: data.stripeSubscription ?? '',
                    stripeSubscriptionStatus: data.stripeSubscriptionStatus ?? '',
                    isSubscribed: data.isSubscribed ?? false,
                    interpolationData: {
                      CompanyName: data.interpolationData?.CompanyName ?? '',
                      CompanyEmail: data.interpolationData?.CompanyEmail ?? '',
                      CompanyWebsite: data.interpolationData?.CompanyWebsite ?? '',
                      CompanyPortal: data.interpolationData?.CompanyPortal ?? '',
                      CompanyPhone: data.interpolationData?.CompanyPhone ?? '',
                      CompanyAddress: data.interpolationData?.CompanyAddress ?? ''
                    },
                    extraFeatures: {
                      sharableStatus: data.extraFeatures?.sharableStatus ?? false
                    }
                }

                setCompanyOptions(co);
            }
        }, true);
    }

    function saveOptions(companyOptions) {
        toastPromise(async () => {
            var data = { ...companyOptions };
            var uploadedFile = await uploadFiles();

            if (uploadedFile) {
              data.logoPath = uploadedFile;
            }

            setDoc(doc(db, `/BusinessRules/${company.companyData?.code}`), data)
                .then((v) => {
                    setCompanyOptions(data);
                })
                .then(() => {
                  company.loadCompany();
                });
        });
    }

    async function uploadFiles() {
        if (!logo || !logo.file || !logo.file.name)
            return null;

        const filename = `${company.companyData?.code}/Logo/${logo.file.name}`;

        if (companyOptions.logoPath !== filename) {
            try {
                const logoRef = ref(storage, filename);
                const snapshot = await uploadBytes(logoRef, logo.file);

                if (snapshot) {
                    console.log("Uploaded");
                }

                return filename;
            } catch (e) {
                console.log(e);
                return null;
            }
        }

        return null;
    }

    function logoAdded(imageFile) {
        setLogo(imageFile);
    }

    function logoRemoved() {
        setLogo(null);
    }

    function render() {
        if (!companyOptions) {
            return (<div className="loadingSpinner"><BeatLoader color={"#045AA1"} /></div>);
        }

        return (
            <div>
                <Formik
                    initialValues={companyOptions}
                    validationSchema={OptionsSchema}
                    onSubmit={values => {
                        // same shape as initial values
                        setCompanyOptions(values);
                        saveOptions(values);
                    }}
                >
                    {({ errors, values, touched }) => (
                        <div>
                            <div style={styles.optionsContainer}>
                                <div style={{ display: 'flex', gap: '32px' }}>
                                    <span style={styles.dot}></span>
                                    <span style={styles.dot}></span>
                                    <span style={styles.dot}></span>
                                </div>
                                <img src="./../assets/computerIcon.png" height={153.59}></img>
                                <div style={{ color: 'white' }}>
                                    <h2 style={{ color: 'white', fontWeight: 'bold' }}>Personalize Your Options</h2>
                                    <span>Configurations available are listed below</span>
                                </div>
                                <div style={{ width: '320px' }}></div>
                            </div>

                            <div className='row' style={styles.configurationContainer}>
                                <div className='col-md-4' style={styles.configOptionsContainer}>
                                  <div style={styles.configOptionsContainerGroup}>
                                    <span>
                                      <span style={{ fontWeight: 'bold', fontSize: '26px' }}>
                                        Company Data
                                      </span>
                                      <Bubble helpText={"Our application uses your company info to personalize the forms. Enter your company information as you want it to appear on your froms here."} width={300}></Bubble>
                                    </span>
                                    <hr style={{ color: 'var(--bs-primary)', borderTop: '2px solid' }} />

                                    <div className="card">
                                        <div className="card-body">
                                            <Form>
                                                <Field errors={errors} name="interpolationData.CompanyName" component={FormikTextInput} formiklabel="Name" />
                                                <Field errors={errors} name="interpolationData.CompanyEmail" component={FormikEmailInput} style={{ width: '100%' }} formiklabel="Email Address" />
                                                <Field errors={errors} name="interpolationData.CompanyPhone" component={FormikTextInput} formiklabel="Phone" />
                                                <Field errors={errors} name="interpolationData.CompanyWebsite" component={FormikTextInput} formiklabel="Website" />
                                                <Field errors={errors} name="interpolationData.CompanyPortal" component={FormikTextInput} formiklabel="Portal" />
                                                <Field errors={errors} name="interpolationData.CompanyAddress" component={FormikTextInput} formiklabel="Address" />
                                            </Form>
                                        </div>
                                    </div>
                                  </div>

                                  <div style={styles.configOptionsContainerGroup}>
                                    <span>
                                      <span style={{ fontWeight: 'bold', fontSize: '26px' }}>
                                        Extra Features
                                      </span>
                                      <Bubble helpText={"Our application has some additional features that may be turned on as needed. View the list below of features to pick and choose what you want."} width={300}></Bubble>
                                    </span>

                                    <hr style={{ color: 'var(--bs-primary)', borderTop: '2px solid' }} />

                                    <div className="card">
                                      <div className="card-body">
                                        <Form>
                                          <Field errors={errors} name="extraFeatures.sharableStatus" component={FormikCheckBoxSingle} formiklabel="Sharable Status">
                                            <Bubble helpText={"This feature allows you to track the status of a tax return and share that status with your clients. When they are finished they will see a progress bar through the return process."} width={300}></Bubble>
                                          </Field>
                                        </Form>
                                      </div>
                                    </div>
                                  </div>
                                </div>

                                <div className='col-md-4' style={styles.configOptionsContainer}>
                                  <div style={styles.configOptionsContainerGroup}>
                                    <span style={{ fontWeight: 'bold', fontSize: '26px' }}>Email</span>
                                    <hr style={{ color: 'var(--bs-primary)', borderTop: '2px solid' }} />

                                    <div className="card">
                                        <div className="card-body">
                                            <Form>
                                                <Field errors={errors} name="emailReply" component={FormikEmailInput} style={{ width: '100%' }} formiklabel="Email Reply Address">
                                                  <Bubble helpText={"The email address used as the TO field when your client replies to their checklist."} width={300}></Bubble>
                                                </Field>

                                                <Field errors={errors} name="emailChecklistStyle" component={FormikSelect} formiklabel="Checklist Style" values={[
                                                    { text: "N/A", value: "" },
                                                    { text: "Bullet", value: "bullet" },
                                                    { text: "Checkbox", value: "checkbox" },
                                                    { text: "Circle", value: "circle" }
                                                ]} />

                                                <br></br>

                                                <h4>Optional settings</h4>

                                                <hr></hr>

                                                <Field errors={errors} name="email" component={FormikEmailInput} style={{ width: '100%' }} formiklabel="Email From Address" />

                                                <Field errors={errors} name="emailProvider" component={FormikSelect} formiklabel="Email Provider" values={[
                                                    { text: "Google", value: "gmail" },
                                                    { text: "Yahoo", value: "yahoo" },
                                                    { text: "Outlook", value: "outlook365" }
                                                ]} />

                                                <Field errors={errors} name="emailKey" component={FormikPasswordInput} formiklabel="Email App Password">
                                                    <Bubble helpText={"An App Password is a email provider generated password that allows applications to send emails on your behalf. Follow the instructions at Account Settings > Get Started > #2 Configure Outgoing Email."} width={300}></Bubble>
                                                </Field>
                                            </Form>
                                        </div>
                                    </div>
                                  </div>
                                </div>

                                <div className='col-md-4' style={styles.configOptionsContainer}>
                                  <div style={styles.configOptionsContainerGroup}>
                                    <span style={{ fontWeight: 'bold', fontSize: '26px' }}>Logo and Style</span>
                                    <hr style={{ color: 'var(--bs-primary)', borderTop: '2px solid' }} />

                                    <div className="card">
                                        <div className="card-body">
                                            <Form>
                                                <Field errors={errors} name="logoPath" component={FormikImageUpload} onFileAdded={logoAdded} onFileRemoved={logoRemoved} />

                                                { editColor == "Primary" ? <Field errors={errors} name="primaryColor" component={FormikColorSelector} formiklabel="Background Theme" /> : null }
                                                { editColor == "Secondary" ? <Field errors={errors} name="secondaryColor" component={FormikColorSelector} formiklabel="Accent Theme" /> : null }
                                                { editColor == "Tertiary" ? <Field errors={errors} name="tertiaryColor" component={FormikColorSelector} formiklabel="Buttons Theme" /> : null }

                                                <div>
                                                  <button type='button' className='btn btn-primary' disabled={editColor == "Primary"} onClick={() => setEditColor("Primary")}>Edit Background</button>
                                                  <div style={{ backgroundColor: values.primaryColor, height: 30, width: 100, display: 'inline-block', marginLeft: 10, borderRadius: 10, textAlign: 'center', alignContent: 'center' }}>Example</div>
                                                </div>

                                                <div style={{ marginTop: 10 }}>
                                                  <button type='button' className='btn btn-primary' disabled={editColor == "Secondary"} onClick={() => setEditColor("Secondary")}>Edit Accent</button>
                                                  <div style={{ backgroundColor: values.secondaryColor, height: 30, width: 100, display: 'inline-block', marginLeft: 10, borderRadius: 10, textAlign: 'center', alignContent: 'center' }}>Example</div>
                                                </div>

                                                <div style={{ marginTop: 10 }}>
                                                  <button type='button' className='btn btn-primary' disabled={editColor == "Tertiary"} onClick={() => setEditColor("Tertiary")}>Edit Button</button>
                                                  <div style={{ backgroundColor: values.tertiaryColor, height: 30, width: 100, display: 'inline-block', marginLeft: 10, borderRadius: 10, textAlign: 'center', alignContent: 'center', color: "#fff" }}>Example</div>
                                                </div>
                                            </Form>
                                        </div>
                                    </div>
                                  </div>
                                </div>
                            </div>

                            <Form style={{ height: 100, display: 'flex', justifyContent: 'center' }}>
                              <button className="btn btn-sm btn-primary mt-3" type="submit" style={{ height: '50px', width: '124px' }}>Save</button>
                            </Form>
                        </div>
                    )}
                </Formik>
            </div >
        );
    }

    return render();
}

const styles = {
    optionsContainer: {
        height: 'auto',
        padding: '20px',
        backgroundColor: 'var(--bs-dark-blue)',
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
        gap: '40px'
    },
    dot: {
        height: '48px',
        width: '48px',
        backgroundColor: 'var(--bs-tope)',
        borderRadius: '50%',
        display: 'inline-block',
    },
    configurationContainer: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        gap: '16px',
        padding: '40px'
    },
    configOptionsContainer: {
        width: '398px',
        height: 'auto',
        display: 'flex',
        flexDirection: 'column'
    },
    configOptionsContainerGroup: {
      marginBottom: 16
    }
}

export default CompanyOptions;