import { v4 as uuidv4 } from 'uuid';

export interface QuestionIndexes {
    Page: number;
    Question: number;
}

export enum QuestionType {
    "NORMAL"="NORMAL",
    "FREE"="FREE",
    "SINGLE"="SINGLE",
    "SINGLE_BUTTONS"="SINGLE_BUTTONS",
    "MULTI"="MULTI",
    "MULTI_BUTTONS"="MULTI_BUTTONS",
    "YESNO"="YESNO",
    "CHECK"="CHECK",
    "ADDRESS"="ADDRESS"
}

export class Submission {
    ID: string;
    FormID: string;
    CompanyID: string;
    IsComplete: boolean = false;
    Processed: boolean = false;
    Questions: SubmissionQuestion[] = [];

    //TODO: Later - add session specific things when we click submit.

    constructor(
        formID: string,
        companyID: string,
        questions?: SubmissionQuestion[]
    ) {
        this.ID = uuidv4();
        this.FormID = formID;
        this.CompanyID = companyID;

        if (questions) {
            this.Questions = questions;
        }
    }

    getQuestion(questionID: string) : SubmissionQuestion | undefined {
        if (!this.Questions) {
            return undefined;
        }

        var index = this.getQuestionIndex(questionID);

        if (index > -1) {
            return this.Questions[index];
        }

        return undefined;
    }

    getQuestionIndex(questionID: string): number {
        return this.Questions.findIndex(o => o.QuestionID === questionID);
    }
}

export class SubmissionQuestion {
    QuestionID: string;
    Type: QuestionType;
    Text: string;
    Answer: string = "";
    Answers: string[] = [];

    OriginIndex: QuestionIndexes;

    constructor(
        questionID: string,
        type: QuestionType,
        text: string,
        origin: QuestionIndexes
    ) {
        this.QuestionID = questionID;
        this.Type = type;
        this.Text = text;
        this.OriginIndex = origin;
    }
}

export class Form {
    maxLimit = 1048576 - 576; // 1 MB max with some wiggle room.

    // Data fields
    ID: string;
    CompanyID: string = "";
    Name: string;
    Type: string;
    Published: boolean = false;
    PublishedDate: Date | null = null;
    Active: boolean = true;
    Pages: Page[] = [];

    //Extra interpretations that aren't tied to specific questions;
    Interpretations: Interpretation[] = [];

    constructor(
        Name: string,
        Type: "BUSINESS" | "INDIVIDUAL" | "ONBOARDING_INTAKE"
    ) {
        this.Name = Name;
        this.Type = Type;
        this.ID = uuidv4();
    }

    getClone(): Form {
        const copy = new (this.constructor as { new (): Form })();
        
        Object.assign(copy, this);

        return copy;
    }

    createSubmission(): Submission {
        let sub = new Submission(this.ID, this.CompanyID);
        var localSubmissionQuestions: SubmissionQuestion[] = [];

        this.Pages.forEach((page, p) => {
            page.Questions.forEach((question, q) => {
                let indexes: QuestionIndexes = {
                    Page: p,
                    Question: q
                };

                localSubmissionQuestions.push(new SubmissionQuestion(question.ID, question.Type, question.Text, indexes));
            });
        });

        sub.Questions = localSubmissionQuestions;

        return sub;
    }

    getSubmissionQuestions() {
    }

    findIndexByQuestionID(id: string): QuestionIndexes {
        let indexes: QuestionIndexes = {
            Page: -1,
            Question: -1
        };
        
        for (let i = 0; i < this.Pages.length; i++) {
            indexes.Question = this.Pages[i].Questions.findIndex(o => o.ID === id);

            if (indexes.Question > -1) {
                indexes.Page = i;
                break;
            }
        }

        return indexes;
    }

    sortAndUpdateAll() {
        this.Pages.sort((a, b) => a.Number - b.Number);

        for (let i = 0; i < this.Pages.length; i++) {
            this.Pages[i].Number = i;
            this.Pages[i].Questions.sort((a, b) => a.Order - b.Order);

            for (let j = 0; j < this.Pages[i].Questions.length; j++) {
                this.Pages[i].Questions[j].Page = i;
                this.Pages[i].Questions[j].Order = j;
            }
        }
    }

    relocateQuestion(src: Question, dest: Question) {
        // remove src id
        this.removeQuestion(src.ID);

        // add src on destination page
        this.addQuestion(dest.Page, src);

        // update src order
        const indexes = this.findIndexByQuestionID(src.ID);

        this.Pages[indexes.Page].Questions[indexes.Question].Page = dest.Page;
        this.Pages[indexes.Page].Questions[indexes.Question].Order = dest.Order;

        // update all questions and pages metadata
        this.sortAndUpdateAll();
    }

    relocateQuestionWithClone(src: Question, dest: Question): Form {
        const copy = this.getClone();

        copy.relocateQuestion(src, dest);

        return copy;
    }

    addPage(p?: Page) {
        if (p) {
            this.Pages.push(p);
            this.Pages[this.Pages.length-1].Number = this.Pages.length-1;
        } else {
            let newPage = new Page("New Page");

            newPage.Number = this.Pages.length;

            this.Pages.push(newPage);
        }
    }

    addPageWithClone(p?: Page): Form {
        const copy = this.getClone();

        copy.addPage(p);

        return copy;
    }

    addQuestion(pageIndex?: number, q?: Question) {
        if (this.Pages.length === 0)
            this.addPage();

        if (pageIndex === undefined)
            pageIndex = this.Pages.length - 1;

        if (q) {
            q.Page = pageIndex;
            q.Order = this.Pages[pageIndex].Questions.length;

            this.Pages[pageIndex].Questions.push(q);
        } else {
            let newQ = new Question("New Question", QuestionType.NORMAL, pageIndex, this.Pages[pageIndex].Questions.length);

            this.Pages[pageIndex].Questions.push(newQ);
        }
    }

    addQuestionWithClone(q?: Question): Form {
        const copy = this.getClone();

        copy.addQuestion(undefined, q);

        return copy;
    }

    removeQuestion(id: string) {
        var indexes = this.findIndexByQuestionID(id);

        if (indexes.Page >= 0 && indexes.Question >= 0) {
            this.Pages[indexes.Page].Questions.splice(indexes.Question, 1);
        }
    }

    removeQuestionWithClone(id: string): Form {
        const copy = this.getClone();

        copy.removeQuestion(id);

        return copy;
    }

    getJsVersionForSaving() {
        return JSON.parse(JSON.stringify(this));
    }
}

export class Question {
    ID: string;
    Text: string;
    Type: QuestionType;
    Options: (string|Option)[] = [];
    Page: number;
    Order: number;
    HelpText: string = "";

    //Helps for visibility, required, validations, etc.
    Visibility: ReferenceEquation[] = [];
    Required: ReferenceEquation[] = [];
    Validations: Validations[] = [];
    Interpretations: Interpretation[] = [];

    // Set by the form renderer
    Answer: string = ""; // single answer types
    Answers: string[] = []; // multi-checkbox answers

    constructor(
        Text: string,
        Type: QuestionType,
        Page: number = 0,
        Order: number = 0,
        Required: ReferenceEquation[] = [new ReferenceEquation("", "", Comparisons.ALWAYS)]
    ) {
        this.ID = uuidv4();
        this.Text = Text;
        this.Type = Type;
        this.Page = Page;
        this.Order = Order;
        
        if (Required) {
          if (Required.length == 1 && Required[0].Comparison == Comparisons.ALWAYS) {
            Required[0].QuestionID = this.ID;
          }

          this.Required = Required;
        }
    }

    getClone(): Question {
        const copy = new (this.constructor as { new (): Question })();
        
        Object.assign(copy, this);

        return copy;
    }
}

export class Option {
  Value: string = "";
  Icon: string = "";

  constructor(value: string, icon: string) {
    this.Value = value;
    this.Icon = icon;
  }

  getClone(): Option {
      const copy = new (this.constructor as { new (): Option })();
      
      Object.assign(copy, this);

      return copy;
  }
}

export class Page {
    Number: number = 0;
    Summary: string;
    Questions: Question[] = [];

    constructor(summary: string) {
        this.Summary = summary;
    }

    getClone(): Page {
        const copy = new (this.constructor as { new (): Page })();
        
        Object.assign(copy, this);

        return copy;
    }
}

export enum Comparisons {
    EQUAL_TO = "EQUAL_TO",
    NOT_EQUAL_TO = "NOT_EQUAL_TO",
    STARTS_WITH = "STARTS_WITH",
    ENDS_WITH = "ENDS_WITH",
    CONTAINS = "CONTAINS",
    EXCLUDES = "EXCLUDES",
    IS_EMPTY = "IS_EMPTY",
    IS_NOT_EMPTY = "IS_NOT_EMPTY",
    ALWAYS = "ALWAYS",
    NEVER = "NEVER"
}

export enum Validations {
    "N/A" = "N/A",
    EMAIL = "EMAIL",
    SSN = "SSN",
    PHONE = "PHONE",
    NUMBER = "NUMBER",
    YEAR = "YEAR"
}

export class ReferenceEquation {
    QuestionID: string;
    Comparison: Comparisons = Comparisons.IS_NOT_EMPTY;
    Value: string;
    OrGroup?: number;

    constructor(questionID: string, value: string, comparison?: Comparisons, orGroup?: number) {
        this.QuestionID = questionID;
        this.Value = value;
        this.OrGroup = orGroup;

        if (comparison)
            this.Comparison = comparison
    }

    getClone(): ReferenceEquation {
        const copy = new (this.constructor as { new (): ReferenceEquation })();
        
        Object.assign(copy, this);

        return copy;
    }
}

export class Interpretation {
    Conditions: ReferenceEquation[] = [];
    Output: string;

    constructor(output: string, conditions?: ReferenceEquation[]) {
        this.Output = output;

        if (conditions)
            this.Conditions = conditions;
    }

    getClone(): Interpretation {
        const copy = new (this.constructor as { new (): Interpretation })();
        
        Object.assign(copy, this);

        return copy;
    }
}