import * as Store from "./store";
import * as CompanyData from "../Helpers/CompanyData";

export const useLoginValidation = () => {
    const {state, dispatch} = Store.useStore();
    return {
        error: state.loginError,
        set: (err) => dispatch({ data: err, type: Store.ACTION_TYPE_AUTHENTICATION_ERROR}),
        clear: () => dispatch({ data: null, type: Store.ACTION_TYPE_AUTHENTICATION_ERROR})
    }
}

export const useUser = () => {
    const {state, dispatch} = Store.useStore();
    return {
        user: state.user,
        set: (user) => dispatch({ data: user, type: Store.ACTION_TYPE_AUTHENTICATION}),
        clear: () => dispatch({ data: null, type: Store.ACTION_TYPE_AUTHENTICATION})
    }
}

export const useScreens = () => {
    const {state, dispatch} = Store.useStore();
    return {
        state: {
            currentScreen: state.currentScreen,
            currentSubScreen: state.currentSubScreen,
            currentSubScreens: state.currentSubScreens,
            navOptions: state.navOptions
        },
        set: (newScreen) => {
            dispatch({ data: { current: "", screens: [] }, type: Store.ACTION_TYPE_SUB_SCREEN});
            dispatch({ data: newScreen, type: Store.ACTION_TYPE_SCREEN});
        },
        setSubScreen: (screen) => {
            var changes = { current: screen, screens: [] };

            if (state.currentSubScreens?.length) {
                var index = state.currentSubScreens.findIndex(v => v.content === screen.content && v.type === screen.type);
                
                if (index > -1) {
                    changes.current = state.currentSubScreens[index];
                    changes.screens = state.currentSubScreens.slice(0, index+1);
                } else {
                    changes.screens = [...state.currentSubScreens, screen];
                }
            } else {
                changes.screens.push(screen);
            }
            
            dispatch({ data: changes, type: Store.ACTION_TYPE_SUB_SCREEN});
        },
        clearSubScreens: () => {
            dispatch({ data: { current: undefined, screens: [] }, type: Store.ACTION_TYPE_SUB_SCREEN});
        },
        clear: () => {
            dispatch({ data: { current: "", screens: [] }, type: Store.ACTION_TYPE_SCREEN});
            dispatch({ data: "", type: Store.ACTION_TYPE_SCREEN});
        }
    }
}

export const useCompany = () => {
    const {state, dispatch} = Store.useStore();
    return {
        companyData: state.companyData,
        setCode: (company) => {
          dispatch({ data: company, type: Store.ACTION_TYPE_COMPANY });
        },
        clear: () => {
          dispatch({ data: null, type: Store.ACTION_TYPE_COMPANY_DATA });
        },
        loadCompany: async (company) => {
          const code = company ?? state.companyData?.code;

          if (code) {
            const data = await CompanyData.getCompanyData(code);
            
            dispatch({ data: {
              code: code,
              ...data
            }, type: Store.ACTION_TYPE_COMPANY_DATA });
          }
        }
    }
}