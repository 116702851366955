import React from 'react';
import { initializeApp } from '@firebase/app';
import { getFirestore, collection, getDocs, getDoc, addDoc, setDoc, deleteDoc, doc, where, query } from '@firebase/firestore';
import EmailEditor from './EmailEditor';
import { toastPromise } from '../Helpers/ToastMessage';
import { useCompany, useScreens } from '../Store/storeFunctions';
import { AgGridReact } from 'ag-grid-react';
import { YesNoCellRenderer, StaticLinkRenderer } from '../Helpers/AgGridCellRenderers';

import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-balham.css';
import FormBuilder from '../Form/FormBuilder';

const app = initializeApp({
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
});

const db = getFirestore(app);

function RuleForm(props) {
  const [edit, setEdit] = React.useState();
  const [links, setLinks] = React.useState(null);

  const userCompany = useCompany();
  const screens = useScreens();
  const gridRef = React.useRef();

  const PROPS = 'PROPS';
  const DEFINITION = 'DEFINITION';

  const isSystem = props.isSystem || userCompany.companyData?.code === "tcpSystemAdministration";

  function fetchLinks() {
    toastPromise(
      async () => {
        var q = query(collection(db, `/formLink`), where("businessName", '==', props.company))
        getDocs(q).then(async l => {
          if (l && l.docs && l.docs.length > 0) {
            var formLinks = [];
            
            for (let i = 0; i < l.docs.length; i++) {
              var data = l.docs[i].data();
              
              data.FormID = l.docs[i].id;
              
              formLinks.push(data);
            }
            
            setLinks(formLinks);
          }
        })
      },
      true
    );
  }

  React.useEffect(() => {
    if (!links) {
      fetchLinks();
    }
  }, []);

  function refresh() {
    fetchLinks();
  }

  function clearForm() {
    screens.set("Rules");
    setEdit("");
  }

  function createNew() {
    toastPromise(
      async () => {
        addDoc(collection(db, '/formLink'), {
          active: true,
          businessName: props.company,
          type: '',
          formName: '',
          emailTemplate: '',
          emailSubject: '',
          ccEmail: false,
          ccEmailAddress: '',
          answersEmailAddress: ''
        }).then(async newLink => {
          if (newLink && newLink.id) {
            editFormLinkProperties(newLink.id);
          }
        })
      },
      true
    );
  }

  function formID() {
    if (screens.state.currentSubScreens.length > 0) {
      var index = screens.state.currentSubScreens.findIndex(o => o.type === "FormRules");

      if (index > -1)
        return screens.state.currentSubScreens[index].data;
      else
        return undefined;
    } else {
      return undefined;
    }
  }

  function formName() {
    if (screens.state.currentSubScreens.length > 0) {
      var index = screens.state.currentSubScreens.findIndex(o => o.type === "FormRules");

      if (index > -1)
        return screens.state.currentSubScreens[index].content;
      else
        return undefined;
    } else {
      return undefined;
    }
  }

  function deleteFormLink() {
    var rows = gridRef.current.api.getSelectedRows();

    if (rows && rows.length === 1) {
      const link = rows[0];
      toastPromise(
        async () => {
          await deleteDoc(doc(db, `/formLink/${link.FormID}`));
          fetchLinks();
        },
        false,
        "Deleting form configuration...",
        "Form configuration deleted.",
        "unable to remove form configuration."
      );
    } else {
      alert("You must select a form first.");
    }
  }

  function editFormLinkProperties(overrideId) {
    var rows = gridRef.current.api.getSelectedRows();

    if (overrideId) {
      screens.setSubScreen({
        content: 'New Form Configuration',
        data: overrideId,
        type: "FormRules"
      });
      setEdit(PROPS);
    } else if (rows && rows.length === 1) {
      const link = rows[0];
      screens.setSubScreen({
        content: link.formName,
        data: link.FormID,
        type: "FormRules"
      });
      setEdit(PROPS);
    } else {
      alert("You must select a form first.");
    }
  }

  // TODO: should we allow the user to make edits to the form from this page? If so add a button for this function and add a form builder tag
  function editFormDefinition() {
    var rows = gridRef.current.api.getSelectedRows();

    if (rows && rows.length === 1) {
      const link = rows[0];
      
      screens.setSubScreen({
        content: link.formName,
        data: link.FormID,
        type: "FormRules"
      });
      setEdit(DEFINITION);
    }
  }

  const columnDefs = [
    {
      checkboxSelection: true,
      valueGetter: (params) => params.data.id,
      resizable: false,
      width: 50,
      sortable: true,
      filter: true
    },
    {
      field: 'formName',
      resizable: true,
      sortable: true,
      filter: true
    },
    {
      field: 'staticLink',
      resizable: true,
      sortable: true,
      filter: true,
      valueGetter: (params) => {
        if (params.data.active && params.data.type) {
          return `https://${process.env.REACT_APP_DOMAIN_NAME}/form/${userCompany.companyData?.code}/${params.data.type}`;
        }
      },
      cellRenderer: StaticLinkRenderer
    },
    {
      field: 'type',
      resizable: true,
      sortable: true,
      filter: true
    },
    {
      field: 'active',
      valueGetter: (params) => {
        if (params.data.active) {
          return "YES";
        } else {
          return "NO";
        }
      },
      cellRenderer: YesNoCellRenderer,
      resizable: true,
      sortable: true,
      filter: true
    },
    {
      field: 'ccEmail',
      valueGetter: (params) => {
        if (params.data.ccEmail) {
          return "YES";
        } else {
          return "NO";
        }
      },
      cellRenderer: YesNoCellRenderer,
      resizable: true,
      sortable: true,
      filter: true
    },
    {
      field: 'ccEmailAddress',
      valueGetter: (params) => {
        if (params.data.ccEmailAddress) {
          return params.data.ccEmailAddress;
        } else {
          return "NO";
        }
      },
      cellRenderer: YesNoCellRenderer,
      resizable: true,
      sortable: true,
      filter: true
    },
    {
      field: 'answersEmailAddress',
      valueGetter: (params) => {
        if (params.data.answersEmailAddress) {
          return params.data.answersEmailAddress;
        } else {
          return "NO";
        }
      },
      cellRenderer: YesNoCellRenderer,
      resizable: true,
      sortable: true,
      filter: true
    },
    {
      field: 'emailSubject',
      valueGetter: (params) => {
        if (params.data.emailSubject) {
          return params.data.emailSubject;
        } else {
          return "NO";
        }
      },
      cellRenderer: YesNoCellRenderer,
      resizable: true,
      sortable: true,
      filter: true
    },
    {
      field: 'emailTemplate',
      valueGetter: (params) => {
        if (params.data.emailTemplate) {
          return "YES";
        } else {
          return "NO";
        }
      },
      cellRenderer: YesNoCellRenderer,
      resizable: true,
      sortable: true,
      filter: true
    }
  ];

  function renderFormTable() {
    return <div style={{ height: 400, width: '90%', position: 'relative' }} className="ag-theme-balham">
      <div style={{position: 'absolute', left: 0, top: -45}}>
        <div className="btn-group">
          <button type="button" className="btn btn-sm btn-primary dropdown-toggle" data-bs-toggle="dropdown">Select Action</button>
          
          <ul className="dropdown-menu" role="menu">
            <li><button className="dropdown-item" onClick={()=>editFormLinkProperties()}>Configure</button></li>
            <li><button className="dropdown-item" onClick={()=>createNew()}>Create New</button></li>
          </ul>
        </div>
        <button type="button" className="btn btn-sm btn-danger" style={{ marginRight: 8 }} onClick={()=>deleteFormLink()}>Delete</button>
      </div>

      <div style={{position: 'absolute', right: 0, top: -45}}>
        <button className="btn btn-info" onClick={()=>refresh()}><span className="bi-arrow-clockwise"></span></button>
      </div>
      
      <AgGridReact
        ref={gridRef}
        rowData={links}
        columnDefs={columnDefs}
        suppressRowClickSelection={true}
        enableCellTextSelection={true}
        rowSelection="single">
      </AgGridReact>
    </div>
  }

  if (formID()) {
    switch (edit) {
      case PROPS: return <EmailEditor FormID={formID()} FormName={formName()} company={props.company} editOptions={props.editOptions} editAdmins={props.editAdmins} viewSubmissions={props.viewSubmissions} back={clearForm} />;
      //TODO: add this back in? case DEFINITION: return <FormBuilder FormID={formID()} company={props.company} editOptions={props.editOptions} editAdmins={props.editAdmins} viewSubmissions={props.viewSubmissions} back={clearForm} />;
    }
  }

  return <div>
    <div className="container-fluid" style={{ margin: 8, marginTop: 40 }}>
      <div className="row" style={{marginRight: 50}}>
        <h5 className="col-sm-9">{isSystem ? `Edit Existing - ${props.company}` : ``}</h5>
      </div>
      
      <div className="row" style={{ marginTop: 10 }}>
        <div className="col-3">
          <img src="../assets/forms icon.png" style={styles.formsicon} width={250}></img>
        </div>

        <div className="col-9">
          {renderFormTable()}
        </div>
      </div>
    </div>
    
  </div>;
}

const styles = {
  formsicon: {
    width: '80%'
  }
}

export default RuleForm;