import React from 'react';
import ImageUploader from 'react-image-upload';
import { getMimeType } from './Helpers/Images';
import { initializeApp } from '@firebase/app';
import { getStorage, ref, getDownloadURL } from "firebase/storage";
import { SketchPicker } from 'react-color';
import Bubble from './Helpers/Bubble';

const app = initializeApp({
    projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
    authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
});

const storage = getStorage(app, process.env.REACT_APP_FIREBASE_STORAGE_BUCKET);

const getError = function(errors, field) {
  if (errors === undefined)
    return null;

  var value = '';

  if (field.name.includes('.')) {
    var parts = field.name.split('.');

    for (let i = 0; i < parts.length; i++) {
      let dottedPart = parts[i];

      if (errors === undefined) {
        return null;
      }

      if (dottedPart.includes('[') && dottedPart.includes(']')) {
        var startIndex = dottedPart.indexOf('[') + 1;
        var endIndex = dottedPart.indexOf(']');
        var index = Number.parseInt(dottedPart.substr(startIndex, endIndex - startIndex));
        var fieldname = dottedPart.substr(0, startIndex - 1);
        
        errors = errors[fieldname];

        if (errors === undefined)
            return null;
        
        errors = errors[index];
      } else {
        errors = errors[parts[i]];
      }
    }

    value = errors;
  } else {
    value = errors[field.name];
  }

  return value;
}

const FormikFormError = ({ ...props }) => {
    const value = getError(props.errors, props.field);

    if (value === "Required") {
        return <span style={{color: 'var(--red)' }}>* Required</span>
    } else if (value) {
        return <span style={{color: 'var(--red)' }}>* {value}</span>
    }
        
    return null;
}

export const FormikCheckBoxSingle = ({ errors, field, form, ...props }) => {
    const children = props.children;
    const usedprops = { ...props, children: undefined };
    var style = {};
    var help = null;

    if (props.formikinline) {
        style.display = "inline";
    }

    if (props.formikvisible === 'false') {
        style.display = "none";
    }

    if (props.formikhelp) {
      help = <Bubble helpText={props.formikhelp} width={300}></Bubble>
    }

    return <div className="mb-3" style={style}>
        <input id={field.name} {...field} checked={field.value} {...usedprops} style={{ marginRight: 8 }} className="form-check-input" type="checkbox" />
        <label htmlFor={field.name} className="form-check-label formikLabel">{props.formiklabel} {children} {help} <FormikFormError errors={errors} field={field} /></label>
    </div>;
}

export const FormikCheckBox = ({ errors, field, form, ...props }) => {
    const children = props.children;
    const usedprops = { ...props, children: undefined };
    var style = {};
    var help = null;

    const hasValue = (option) => { 
        if (!field.value) return false;
        return field.value?.findIndex((o) => o === option) !== -1;
    }

    if (props.formikinline) {
        style.display = "inline";
    }

    if (props.formikvisible === 'false') {
        style.display = "none";
    }

    if (props.formikhelp) {
      help = <Bubble helpText={props.formikhelp} width={300}></Bubble>
    }

    return <div id={field.name} className="mb-3" style={style}>
        <label htmlFor={field.name} className="form-check-label formikLabel">{props.formiklabel} {children} {help} <FormikFormError errors={errors} field={field} /></label>
        <div role="group" aria-labelledby={field.name}>
            {
                props.options.map((option) => 
                    <div key={`${field.name}_${option}`}>
                        <label>
                            <input id={`${field.name}`} style={{marginRight:10}} {...field} value={option} checked={hasValue(option)} {...usedprops} className="form-checkbox-input" type="checkbox" />
                            {option}
                        </label>
                    </div>
                )
            }
        </div>
    </div>;
}

export const FormikRadioYN = ({ errors, field, form, ...props }) => {
    const children = props.children;
    const usedprops = { ...props, children: undefined };
    var style = {};
    var help = null;

    if (props.formikinline) {
        style.display = "inline";
    }

    if (props.formikvisible === 'false') {
        style.display = "none";
    }

    if (props.formikhelp) {
      help = <Bubble helpText={props.formikhelp} width={300}></Bubble>
    }

    return <div id={field.name} className="mb-3" style={style}>
        <label htmlFor={field.name} className="form-check-label formikLabel">{props.formiklabel} {children} {help} <FormikFormError errors={errors} field={field} /></label>
        <div role="group" aria-labelledby={field.name}>
            <label style={{marginLeft:10}}>
                <input id={`${field.name}-Y`} {...field} value="Yes" checked={field.value === 'Yes'} {...usedprops} className="form-radio-input" type="radio" />&nbsp;
                Yes
            </label>
            <label style={{marginLeft:10}}>
                <input id={`${field.name}-N`} {...field} value="No" checked={field.value === 'No'} {...usedprops} className="form-radio-input" type="radio" />&nbsp;
                No
            </label>
        </div>
    </div>;
}

export const FormikRadio = ({ errors, field, form, ...props }) => {
    const children = props.children;
    const usedprops = { ...props, children: undefined };
    var style = {};
    var help = null;

    if (props.formikinline) {
        style.display = "inline";
    }

    if (props.formikvisible === 'false') {
        style.display = "none";
    }

    if (props.formikhelp) {
      help = <Bubble helpText={props.formikhelp} width={300}></Bubble>
    }

    return <div className="mb-3" style={style}>
        <label htmlFor={field.name} className="form-check-label formikLabel">{props.formiklabel} {children} {help} <FormikFormError errors={errors} field={field} /></label>
        <div role="group" aria-labelledby={field.name}>
            {
                props.options.map((option) => {
                    return <div key={`{${field.name}_${option}`}>
                        <label style={{marginLeft:10}}>
                            <input id={`${field.name}`} {...field} value={option} checked={field.value === option} {...usedprops} className="form-radio-input" type="radio" style={{marginRight:5}} />
                            {option}
                        </label>
                    </div>
                })
            }
        </div>
    </div>;
}

export const FormikTextInput = ({ errors, field, form, ...props }) => {
    const children = props.children;
    const usedprops = { ...props, children: undefined };
    var style = {};
    var inputStyle = { width: '100%' };
    var help = null;

    if (props.formikinline) {
        style.display = "inline";
    }

    if (props.formikvisible === 'false') {
        style.display = "none";
    }

    if (props.formikhelp) {
      help = <Bubble helpText={props.formikhelp} width={300}></Bubble>
    }

    if (props.formikinline) {
      inputStyle.width = "65%";
      inputStyle.display = "inline-block";
      inputStyle.marginLeft = 5;
    }

    return <div className="mb-3" style={style}>
        <label htmlFor={field.name} className="form-label formikLabel">{props.formiklabel} {children} {help} <FormikFormError errors={errors} field={field} /></label>
        <div style={props.formikinline ? { display: "inline" } : {}}>
            <input className="form-control form-control-sm" style={inputStyle} type="text" {...field} {...usedprops} />
        </div>
    </div>;
};

export const FormikTextAreaInput = ({ errors, field, form, ...props }) => {
    const children = props.children;
    const usedprops = { ...props, children: undefined };
    var style = {};
    var help = null;

    if (props.formikinline) {
        style.display = "inline";
    }

    if (props.formikvisible === 'false') {
        style.display = "none";
    }

    if (props.formikhelp) {
      help = <Bubble helpText={props.formikhelp} width={300}></Bubble>
    }

    return <div className="mb-3" style={style}>
        <label htmlFor={field.name} className="form-label formikLabel">{props.formiklabel} {children} {help} <FormikFormError errors={errors} field={field} /></label>
        <div>
            <textarea className="form-control form-control-sm" style={props.formikinline ? { width: '65%', display: 'inline-block', marginLeft: 5 } : { width: '100%' }} rows={props.formikrows ?? 10} {...field} {...usedprops} />
        </div>
    </div>;
};

export const FormikButtonSelectInput = ({ errors, field, form, ...props }) => {
  const children = props.children;
  const usedprops = { ...props, children: undefined };
  var style = {};
  var help = null;

  const hasValue = (option) => {
      if (!field.value) return false;
      return field.value?.findIndex((o) => o === option) !== -1;
  }

  const updateField = (option) => {
    if (props.formikmulti) {
      if (hasValue(option)) {
        var valIndex = field.value.findIndex(o => o == option);

        val = field.value.splice(valIndex, 1);

        form.setFieldValue(field.name, val);
      } else {
        var vals = [ ...field.value, option ];
        
        form.setFieldValue(field.name, vals.join(","));
      }
    } else {
      if (hasValue(option)) {
        form.setFieldValue(field.name, []);
      } else {
        form.setFieldValue(field.name, [option]);
      }
    }
  }

  if (props.formikinline) {
      style.display = "inline";
  }

  if (props.formikvisible === 'false') {
      style.display = "none";
  }

  if (props.formikhelp) {
    help = <Bubble helpText={props.formikhelp} width={300}></Bubble>
  }

  return <div id={field.name} className="mb-3" style={style}>
    <label htmlFor={field.name} className="form-check-label formikLabel">{props.formiklabel} {children} {help} <FormikFormError errors={errors} field={field} /></label>

    <div role="group" aria-labelledby={field.name} style={{
      display: 'flex',
      width: '100%',
      flexWrap: 'wrap',
      flexDirection: 'row'
    }}>
      {
        props.options.map((option) => {
          var selected = hasValue(option);

          return <div key={`${field.name}_${option}`}>
            <button id={`${field.name}`}
              style={{
                margin: 10,
                padding: 15,
                height: 'auto',
                width: 250,
                borderWidth: selected ? 3 : 1,
                borderStyle: 'solid',
                borderColor: selected ? 'var(--green)' : 'black',
                fontWeight: selected ? 'bold' : "400"
              }}
              {...field}
              value={option}
              {...usedprops}
              className={`btn btn-info`}
              onClick={() => updateField(option)}
            >
              { props.formikicon ? <><span style={{ fontSize: 30 }} className={`bi-${props.formikicon}`}></span><br /></> : <></> }
              {option}
            </button>
          </div>
        })
      }
    </div>
  </div>;
};

export const FormikPasswordInput = ({ errors, field, form, ...props }) => {
    const [viewType, setViewType] = React.useState('password');
    const children = props.children;
    const usedprops = { ...props, children: undefined };
    var style = {};

    if (props.formikinline) {
        style.display = "inline";
    }

    if (props.formikvisible === 'false') {
        style.display = "none";
    }

    var inputStyle = usedprops.style ?? {};

    inputStyle.width = "calc(100% - 36px)";

    return <div className="mb-3" style={style}>
        <label htmlFor={field.name} className="form-label formikLabel">{props.formiklabel} {children} <FormikFormError errors={errors} field={field} /></label>
        <div className="input-group mb-3" style={props.formikinline ? { width: '65%', display: 'inline-block', marginLeft: 5 } : {}}>
            <input type={viewType} className="form-control form-control-sm pwd" {...field} {...usedprops} style={inputStyle}  />
            <button className="btn btn-sm btn-primary reveal" type="button" onClick={() => {
                if (viewType === "password") {
                    setViewType("text");
                } else {
                    setViewType("password");
                }
            }}>{viewType === "password" ? <i className="bi-eye"></i> : <i className="bi-eye-slash"></i>}</button>
        </div>
    </div>;
};

export const FormikInlineEmailInput = ({ errors, field, form, ...props }) => {
    var style = {};
    var help = null;

    if (props.formikinline) {
        style.display = "inline";
    }

    if (props.formikvisible === 'false') {
        style.display = "none";
    }

    if (props.formikhelp) {
      help = <Bubble helpText={props.formikhelp} width={300}></Bubble>
    }

    return <div className="mb-3" style={style}>
        <label htmlFor={field.name} className="form-label formikLabel">{props.formiklabel} {help} <FormikFormError errors={errors} field={field} /></label>
        <input className="form-control form-control-sm" style={props.formikinline ? { width: '65%', display: 'inline-block', marginLeft: 5 } : {}} type="email" {...field} {...props} />
    </div>;
};

export const FormikEmailInput = ({ errors, field, form, ...props }) => {
    const children = props.children;
    const usedprops = { ...props, children: undefined };
    var style = {};
    var help = null;

    if (props.formikinline) {
        style.display = "inline";
    }

    if (props.formikvisible === 'false') {
        style.display = "none";
    }

    if (props.formikhelp) {
      help = <Bubble helpText={props.formikhelp} width={300}></Bubble>
    }

    return <div className="mb-3" style={style}>
        <label htmlFor={field.name} className="form-label formikLabel">{props.formiklabel} {children} {help} <FormikFormError errors={errors} field={field} /></label>
        <div>
            <input className="form-control form-control-sm" style={props.formikinline ? { width: '65%', display: 'inline-block', marginLeft: 5 } : {}} type="email" {...field} {...usedprops} />
        </div>
    </div>;
};

export const FormikSelect = ({ errors, field, form, ...props }) => {
    var style = {};

    if (props.formikinline) {
        style.display = "inline";
    }

    if (props.formikvisible === 'false') {
        style.display = "none";
    }

    return <div className="mb-3" style={style}>
        <label className="form-label formikLabel">{props.formiklabel} <FormikFormError errors={errors} field={field} /></label>
        <select className="form-select form-select-sm" style={props.formikinline ? { width: '65%', display: 'inline-block', marginLeft: 5 } : {}} {...field}>
            <option value={""}>Choose an option</option>
            {props.values.map(kvp => { return <option key={`Select${props.formiklabel}-${kvp.value}`} value={kvp.value}>{kvp.text}</option> })}
        </select>
    </div>;
};

export const FormikAddressInput = ({ errors, field, form, ...props }) => {
  const [addressLine1, setAddressLine1] = React.useState("");
  const [addressLine2, setAddressLine2] = React.useState("");
  const [city, setCity] = React.useState("");
  const [state, setState] = React.useState("");
  const [zip, setZip] = React.useState("");

  React.useEffect(() => {
    const hasNotSet = addressLine1 == "" && addressLine2 == "" && city == "" && state == "" && zip == "";

    if (field.value && hasNotSet) {
      var parts = field.value.split(';');

      if (parts.length == 5) {
        setAddressLine1(parts[0]);
        setAddressLine2(parts[1]);
        setCity(parts[2]);
        setState(parts[3]);
        setZip(parts[4]);
      }
    }
  }, [field.value]);

  function changeAddressLine1(v) {
    setAddressLine1(v.target.value);
    var address = `${v.target.value};${addressLine2??""};${city??""};${state??""};${zip??""}`;
    form.setFieldValue(field.name, address);
  }

  function changeAddressLine2(v) {
    setAddressLine2(v.target.value);
    var address = `${addressLine1??""};${v.target.value};${city??""};${state??""};${zip??""}`;
    form.setFieldValue(field.name, address);
  }

  function changeCity(v) {
    setCity(v.target.value);
    var address = `${addressLine1??""};${addressLine2??""};${v.target.value};${state??""};${zip??""}`;
    form.setFieldValue(field.name, address);
  }

  function changeState(v) {
    setState(v.target.value);
    var address = `${addressLine1??""};${addressLine2??""};${city??""};${v.target.value};${zip??""}`;
    form.setFieldValue(field.name, address);
  }

  function changeZip(v) {
    setZip(v.target.value);
    var address = `${addressLine1??""};${addressLine2??""};${city??""};${state??""};${v.target.value}`;
    form.setFieldValue(field.name, address);
  }

  var help = null;
  var style = {};

  if (props.formikhelp) {
    help = <Bubble helpText={props.formikhelp} width={300}></Bubble>
  }

  if (props.formikvisible === 'false') {
      style.display = "none";
  }

  return <div style={style}>
    <div className="mb-3">
        <label htmlFor={`${field.name}.line1`} className="form-label formikLabel">Address Line 1 {help} <FormikFormError errors={errors} field={field} /></label>
        <div>
            <input className="form-control form-control-sm" autoComplete='address-line1' style={props.formikinline ? { width: '65%', display: 'inline-block', marginLeft: 5 } : { width: '100%' }} type="text" value={addressLine1} onChange={changeAddressLine1} />
        </div>
    </div>

    <div className="mb-3">
        <label htmlFor={`${field.name}.line2`} className="form-label formikLabel">Address Line 2 {help} <FormikFormError errors={errors} field={field} /></label>
        <div>
            <input className="form-control form-control-sm" autoComplete='address-line2' style={props.formikinline ? { width: '65%', display: 'inline-block', marginLeft: 5 } : { width: '100%' }} type="text" value={addressLine2} onChange={changeAddressLine2} />
        </div>
    </div>

    <div className="mb-3">
        <label htmlFor={`${field.name}.city`} className="form-label formikLabel">City {help} <FormikFormError errors={errors} field={field} /></label>
        <div>
            <input className="form-control form-control-sm" autoComplete='address-level2' style={props.formikinline ? { width: '65%', display: 'inline-block', marginLeft: 5 } : { width: '100%' }} type="text" value={city} onChange={changeCity} />
        </div>
    </div>

    <div className="mb-3">
      <label className="form-label formikLabel">State <FormikFormError errors={errors} field={field} /></label>
      <select className="form-select form-select-sm" autoComplete='address-level1' style={props.formikinline ? { width: '65%', display: 'inline-block', marginLeft: 5 } : {}} value={state} onChange={changeState}>
          <option value={""}>Choose an option</option>
          <option value={"Alabama"}>Alabama</option>
          <option value={"Alaska"}>Alaska</option>
          <option value={"Arizona"}>Arizona</option>
          <option value={"Arkansas"}>Arkansas</option>
          <option value={"California"}>California</option>
          <option value={"Colorado"}>Colorado</option>
          <option value={"Connecticut"}>Connecticut</option>
          <option value={"Delaware"}>Delaware</option>
          <option value={"Florida"}>Florida</option>
          <option value={"Georgia"}>Georgia</option>
          <option value={"Hawaii"}>Hawaii</option>
          <option value={"Idaho"}>Idaho</option>
          <option value={"Illinois"}>Illinois</option>
          <option value={"Indiana"}>Indiana</option>
          <option value={"Iowa"}>Iowa</option>
          <option value={"Kansas"}>Kansas</option>
          <option value={"Kentucky"}>Kentucky</option>
          <option value={"Louisiana"}>Louisiana</option>
          <option value={"Maine"}>Maine</option>
          <option value={"Maryland"}>Maryland</option>
          <option value={"Massachusetts"}>Massachusetts</option>
          <option value={"Michigan"}>Michigan</option>
          <option value={"Minnesota"}>Minnesota</option>
          <option value={"Mississippi"}>Mississippi</option>
          <option value={"Missouri"}>Missouri</option>
          <option value={"Montana"}>Montana</option>
          <option value={"Nebraska"}>Nebraska</option>
          <option value={"Nevada"}>Nevada</option>
          <option value={"New Hampshire"}>New Hampshire</option>
          <option value={"New Jersey"}>New Jersey</option>
          <option value={"New Mexico"}>New Mexico</option>
          <option value={"New York"}>New York</option>
          <option value={"North Carolina"}>North Carolina</option>
          <option value={"North Dakota"}>North Dakota</option>
          <option value={"Ohio"}>Ohio</option>
          <option value={"Oklahoma"}>Oklahoma</option>
          <option value={"Oregon"}>Oregon</option>
          <option value={"Pennsylvania"}>Pennsylvania</option>
          <option value={"Rhode Island"}>Rhode Island</option>
          <option value={"South Carolina"}>South Carolina</option>
          <option value={"South Dakota"}>South Dakota</option>
          <option value={"Tennessee"}>Tennessee</option>
          <option value={"Texas"}>Texas</option>
          <option value={"Utah"}>Utah</option>
          <option value={"Vermont"}>Vermont</option>
          <option value={"Virginia"}>Virginia</option>
          <option value={"Washington"}>Washington</option>
          <option value={"West Virginia"}>West Virginia</option>
          <option value={"Wisconsin"}>Wisconsin</option>
          <option value={"Wyoming"}>Wyoming</option>
      </select>
    </div>

    <div className="mb-3">
        <label htmlFor={`${field.name}.zip`} className="form-label formikLabel">Postal / Zip Code {help} <FormikFormError errors={errors} field={field} /></label>
        <div>
            <input className="form-control form-control-sm" autoComplete='postal-code' style={props.formikinline ? { width: '65%', display: 'inline-block', marginLeft: 5 } : { width: '100%' }} type="number" value={zip} onChange={changeZip} />
        </div>
    </div>
  </div>;
};

export const FormikColorSelector = ({ errors, field, form, ...props }) => {
    return <div className="mb-3">
        <label className="form-label formikLabel">{props.formiklabel} <FormikFormError errors={errors} field={field} /></label>
        <SketchPicker color={field.value} onChange={(color, e) => {
            form.setFieldValue(field.name, color.hex);
        }} />
    </div>;
}

export const FormikImageUpload = ({ errors, field, form, ...props }) => {
    const [imageSource, setImageSource] = React.useState();

    React.useEffect(() => {
        if (field.value) {
            const logoRef = ref(storage, field.value);

            getDownloadURL(logoRef).then(url => {
                setImageSource(url);
            });
        }

    }, [field.value]);

    return <div className="mb-3 row">
        <label className="form-label formikLabel">{props.formiklabel} (JPG or PNG, Max 5MB) <FormikFormError errors={errors} field={field} /></label>
        <div className="col">
            <ImageUploader onFileAdded={props.onFileAdded}
                onFileRemoved={props.onFileRemoved}
                style={{ height: 100, width: 234 }}
                uploadIcon={
                    <div>Upload</div>
                }
            />
        </div>
        { !imageSource ? null :
          <div className="col">
              <img style={{ height: 200, width: 300 }} alt=""
                  src={imageSource}
              />

              <button className="btn btn-sm btn-primary" href={`data:${getMimeType(field.value)},${imageSource}`} target="_blank">Download</button>
          </div>
        }
    </div>;
};

